
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loading from "@/components/Loading.vue";
import { translate, currentLocale } from "@/locales/i18n";
import { ISerie, IDemo } from "@/types/api.type";
import { fetchSerie } from "@/share/fetch";
import { getImageUrl } from "@/share/fetch";

@Options({
  name: "demo",
  components: {
    Loading,
  },
  methods: {
    getImageUrl: getImageUrl,
  },
})
export default class Demo extends Vue {
  /* -------------------------------------------------------------------------- */
  /*                                   States                                   */
  /* -------------------------------------------------------------------------- */
  backendUrl = process.env.VUE_APP_BACKEND_URL;
  t = translate;
  loading = true;
  serie: ISerie | undefined = undefined;
  demo: IDemo | undefined = undefined;

  /* -------------------------------------------------------------------------- */
  /*                                   Methods                                  */
  /* -------------------------------------------------------------------------- */

  async fetchData() {
    // enable loading anination
    this.loading = true;

    try {
      // fetch data from url params id
      const serieId = this.$route?.params?.id;
      if (!Number.isInteger(+serieId)) return this.$router.push("/404");
      this.serie = await fetchSerie(+serieId);
      this.demo = this.serie.attributes?.demo?.data;

      if (!this.demo) throw new Error();
    } catch (e) {
      // handle fetct fail --> to 404 page
      return this.$router.push("/404");
    }

    // delay loading animation
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  mounted() {
    this.fetchData();
  }

  /* -------------------------------------------------------------------------- */
  /*                                   Watches                                  */
  /* -------------------------------------------------------------------------- */
}
